import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "./ui/Button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui/DropdownMenu";
import { User, Search, ChevronDown, ChevronUp, Menu, X } from 'lucide-react';
import { motion, AnimatePresence } from "framer-motion";

interface ConditionInfo {
    [key: string]: string;
}

export default function HealthEncyclopedia() {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [expandedCondition, setExpandedCondition] = useState<string | null>(null);
  const [conditionInfo, setConditionInfo] = useState<ConditionInfo>({});
  const [searchTerm, setSearchTerm] = useState('');

  const mainCategories = [
    { name: "All", icon: "🔍" },
    { name: "Skin Conditions", icon: "🧴" },
    { name: "Eye Conditions", icon: "👁️" },
    { name: "Nail Conditions", icon: "💅" },
    { name: "Melanoma", icon: "🔬" },
  ];

  const allConditions = [
    // Skin Conditions
    { name: "Acne and Rosacea", category: "Skin Conditions", severity: "Mild to Moderate", info: "Acne and rosacea are common skin conditions affecting the face. Acne, typically seen in adolescents, is characterized by comedones, papules, pustules, and sometimes nodules or cysts. It's diagnosed clinically and treated with topical retinoids, benzoyl peroxide, antibiotics, or isotretinoin for severe cases. Rosacea, more common in adults over 30, causes facial redness, flushing, visible blood vessels, and occasionally papules and pustules. Diagnosis is based on clinical features. Treatment options include topical metronidazole, azelaic acid, or ivermectin, with oral antibiotics for more severe cases. Laser treatments can address persistent redness. Both conditions may require long-term management and can significantly impact quality of life." },
    { name: "Actinic Keratosis Basal Cell Carcinoma", category: "Skin Conditions", severity: "Severe", info: "Actinic Keratosis (AK) and Basal Cell Carcinoma (BCC) are skin conditions caused by cumulative sun exposure. AK presents as rough, scaly patches and is considered precancerous, while BCC is a type of skin cancer characterized by pearly, flesh-colored bumps or pinkish patches. Diagnosis for both conditions typically involves a visual examination by a dermatologist, with skin biopsies sometimes performed for confirmation, especially for BCC. Treatment options for AK include cryotherapy, topical medications like fluorouracil or imiquimod, photodynamic therapy, and curettage. For BCC, common treatments are surgical excision, Mohs surgery, radiation therapy, and topical medications, with targeted therapies or immunotherapy used in advanced cases. Both conditions require ongoing monitoring and sun protection to prevent recurrence or progression, and early detection and treatment are crucial for optimal outcomes." },
    { name: "Atopic Dermatitis", category: "Skin Conditions", severity: "Moderate", info: "Atopic dermatitis, commonly known as eczema, is a chronic inflammatory skin condition characterized by dry, itchy skin and recurring rashes. It typically begins in childhood and can persist into adulthood. Diagnosis is primarily clinical, based on symptoms, skin examination, and medical history. Common signs include red, scaly patches, intense itching, and skin thickening. Treatment focuses on managing symptoms and preventing flare-ups. First-line therapies include regular use of emollients and topical corticosteroids. For moderate to severe cases, additional options may include topical calcineurin inhibitors, phototherapy, or systemic medications such as oral corticosteroids, immunosuppressants, or biologic agents like dupilumab. Antihistamines can help manage itching, while antibiotics may be necessary for secondary infections. Identifying and avoiding triggers, maintaining proper skin care, and using wet wraps can also help control symptoms. Although there is no cure, most patients can effectively manage their condition with appropriate treatment and lifestyle modifications." },
    { name: "Bullous Disease", category: "Skin Conditions", severity: "Moderate", info: "Bullous diseases are a group of autoimmune skin disorders characterized by the formation of blisters and bullae on the skin and mucous membranes. The most common types are pemphigus and bullous pemphigoid. These conditions typically affect older adults and are caused by autoantibodies attacking specific proteins in the skin, leading to separation of skin layers. Diagnosis involves clinical examination, skin biopsy with histopathology, direct immunofluorescence to detect antibody deposits, and serological tests to identify circulating autoantibodies. Treatment options vary depending on disease severity but often include topical and systemic corticosteroids as first-line therapy. For moderate to severe cases, immunosuppressive agents like azathioprine, mycophenolate mofetil, or methotrexate may be used. In refractory cases, rituximab or intravenous immunoglobulin might be considered. Antibiotics may be prescribed to prevent secondary infections. Management typically requires long-term care, with the goal of achieving remission and minimizing side effects from medications." },
    { name: "Cellulitis Impetigo", category: "Skin Conditions", severity: "Moderate", info: "Cellulitis and impetigo are bacterial skin infections with distinct characteristics. Cellulitis is a deep skin infection affecting the dermis and subcutaneous tissue, causing redness, swelling, warmth, and tenderness in the affected area. Impetigo, on the other hand, is a highly contagious superficial skin infection characterized by red sores that quickly form honey-colored crusts. Both conditions are typically diagnosed through clinical examination, with bacterial cultures sometimes used for confirmation. In severe cases of cellulitis, blood tests may be necessary. Treatment for cellulitis usually involves oral antibiotics, while impetigo can often be managed with topical antibiotics for mild cases and oral antibiotics for more extensive infections. In both conditions, proper wound care and hygiene are essential. Cellulitis may require hospitalization and intravenous antibiotics if severe or if oral treatment is ineffective. Prompt treatment is crucial to prevent complications and spread of infection" },
    { name: "Eczema", category: "Skin Conditions", severity: "Mild to Moderate", info: "Eczema, also known as atopic dermatitis, is a chronic inflammatory skin condition characterized by dry, itchy, and inflamed skin. It typically appears as red, scaly patches that can become cracked, oozing, or crusty when scratched. Eczema often begins in childhood and may persist into adulthood, with flare-ups triggered by various factors such as stress, allergens, or irritants. Diagnosis is primarily based on clinical presentation, patient history, and physical examination. In some cases, patch testing or skin biopsies may be performed to rule out other conditions. Treatment focuses on managing symptoms and preventing flare-ups. Common approaches include regular use of moisturizers, topical corticosteroids for inflammation, and antihistamines for itching. For moderate to severe cases, additional options may include topical calcineurin inhibitors, phototherapy, or systemic medications such as oral corticosteroids or immunosuppressants. Identifying and avoiding triggers, maintaining proper skin care, and using gentle, fragrance-free products are essential components of eczema management." },
    { name: "Exanthems and Drug Eruptions", category: "Skin Conditions", severity: "Moderate", info: "Exanthems and drug eruptions are skin reactions typically characterized by widespread rashes. Exanthems are often caused by viral infections, while drug eruptions result from medication use. These conditions present as various types of rashes, including maculopapular, urticarial, or pustular eruptions. Diagnosis is primarily based on clinical presentation, patient history, and physical examination. In some cases, blood tests, skin biopsies, or patch testing may be necessary to confirm the cause. For viral exanthems, treatment is usually supportive, focusing on symptom relief. Drug eruptions generally improve upon discontinuation of the offending medication. Common treatments for both conditions include antihistamines and topical corticosteroids to manage itching and inflammation. In severe cases, such as Stevens-Johnson syndrome or toxic epidermal necrolysis, hospitalization and intensive care may be required. Prevention strategies include avoiding known triggers and practicing good hygiene to reduce the risk of viral infections." },
    { name: "Herpes HPV and other STDs", category: "Skin Conditions", severity: "Moderate", info: "Herpes, Human Papillomavirus (HPV), and other sexually transmitted diseases (STDs) are infections spread primarily through sexual contact. Herpes, caused by HSV-1 or HSV-2, presents as painful blisters on the genitals or mouth. HPV can cause genital warts and is linked to certain cancers, including cervical cancer. Other common STDs include chlamydia, gonorrhea, and syphilis. Diagnosis typically involves physical examination, patient history, and laboratory tests such as blood tests, urine samples, or swabs from affected areas. Treatment varies depending on the specific STD. Viral infections like herpes and HPV have no cure but can be managed with antiviral medications. Bacterial STDs are usually treated with antibiotics. Prevention methods include practicing safe sex, using condoms, limiting sexual partners, and getting vaccinated against HPV and hepatitis B. Regular screening is crucial, especially for high-risk individuals, as many STDs can be asymptomatic. Early detection and treatment are essential to prevent complications and reduce transmission." },
    { name: "Light Diseases and Disorders of Pigmentation", category: "Skin Conditions", severity: "Mild", info: "Light diseases and disorders of pigmentation encompass a range of conditions affecting skin color, with vitiligo and melasma being common examples. Vitiligo is characterized by patches of depigmented skin due to melanocyte loss, while melasma presents as dark patches typically on the face. Diagnosis usually involves clinical examination, Wood's lamp examination, and sometimes skin biopsies or blood tests. Treatment options vary depending on the specific condition and severity. For vitiligo, treatments include topical corticosteroids, calcineurin inhibitors, phototherapy (especially narrow band UVB), and in some cases, surgical interventions like skin grafting. Melasma is often treated with topical agents such as hydroquinone, tretinoin, or combination therapies, as well as chemical peels and laser treatments. Sun protection is crucial for managing both conditions. In cases of widespread depigmentation, depigmentation therapy may be considered. Treatment goals focus on restoring uniform skin color and preventing progression, often requiring long-term management." },
    { name: "Lupus and other Connective Tissue Diseases", category: "Skin Conditions", severity: "Severe", info: "Lupus and other connective tissue diseases are chronic autoimmune disorders that affect various body systems. Lupus, the most common, causes inflammation in tissues like skin, joints, and organs. These conditions are typically diagnosed through a combination of clinical symptoms, physical examination, blood tests for autoantibodies, and sometimes imaging or biopsies. Common symptoms include fatigue, joint pain, skin rashes, and organ involvement. Treatment aims to manage symptoms, prevent flares, and minimize organ damage. Options include nonsteroidal anti-inflammatory drugs for mild symptoms, antimalarials like hydroxychloroquine, corticosteroids for inflammation, and immunosuppressants such as methotrexate or mycophenolate mofetil for severe cases. Newer biologics, like belimumab for lupus, target specific immune pathways. Treatment is often individualized and may change over time based on disease activity and organ involvement. Lifestyle modifications, sun protection, and regular monitoring are essential components of managing these complex conditions." },
    { name: "Poison Ivy and other Contact Dermatitis", category: "Skin Conditions", severity: "Mild", info: "Poison ivy and other forms of contact dermatitis are inflammatory skin reactions caused by direct contact with irritants or allergens. Poison ivy, specifically, results from exposure to urushiol oil found in certain plants. Contact dermatitis presents as red, itchy, and sometimes blistering rashes. Diagnosis is typically based on patient history, physical examination, and the pattern of the rash. In some cases, patch testing may be used to identify specific allergens. Treatment focuses on symptom relief and includes topical corticosteroids to reduce inflammation, oral antihistamines for itching, and cool compresses. In severe cases, oral corticosteroids may be prescribed. For poison ivy, thoroughly washing the exposed area immediately after contact can help minimize the reaction. Prevention strategies involve identifying and avoiding known triggers, using protective clothing, and applying barrier creams when exposure is anticipated. Most cases resolve within a few weeks, but chronic contact dermatitis may require long-term management and lifestyle adjustments." },
    { name: "Psoriasis", category: "Skin Conditions", severity: "Moderate", info: "Psoriasis is a chronic autoimmune skin condition characterized by rapid skin cell turnover, resulting in thick, red, scaly patches on the skin. It can affect various body parts, including the scalp, elbows, knees, and lower back. Diagnosis is typically based on clinical presentation, family history, and physical examination. In some cases, a skin biopsy may be performed to confirm the diagnosis or rule out other conditions. Treatment options vary depending on the severity and type of psoriasis. Mild cases are often managed with topical treatments such as corticosteroids, vitamin D analogs, or retinoids. For moderate to severe cases, phototherapy, systemic medications like methotrexate or cyclosporine, and biologic drugs targeting specific immune pathways may be prescribed. Lifestyle modifications, including stress reduction and maintaining a healthy weight, can also help manage symptoms. While there is no cure for psoriasis, many patients achieve significant improvement and long periods of remission with appropriate treatment." },
    { name: "Scabies Lyme Disease", category: "Skin Conditions", severity: "Moderate", info: "Scabies and Lyme disease are distinct conditions caused by different organisms. Scabies is a skin infestation caused by tiny mites, resulting in intense itching and a rash. Lyme disease is a tick-borne illness caused by Borrelia bacteria, often presenting with a characteristic bull's-eye rash and flu-like symptoms. Scabies is diagnosed through clinical examination and microscopic identification of mites or eggs in skin scrapings. Lyme disease diagnosis involves clinical evaluation, patient history, and blood tests for antibodies. Treatment for scabies typically involves topical medications like permethrin cream or oral ivermectin, applied to the entire body. Lyme disease is treated with antibiotics, usually oral doxycycline for early stages or intravenous antibiotics for more severe cases. Both conditions require treatment of close contacts to prevent spread. Prevention strategies include avoiding skin-to-skin contact for scabies and using tick repellents and proper tick removal for Lyme disease." },
    { name: "Seborrheic Keratoses", category: "Skin Conditions", severity: "Mild", info: "Seborrheic keratoses are common, benign skin growths that typically appear in middle-aged and older adults. These growths are characterized by waxy, scaly, slightly raised lesions that can range in color from light tan to dark brown or black. They often have a \"stuck-on\" appearance and can occur anywhere on the body, but are most common on the face, chest, shoulders, and back. Diagnosis is usually made through visual examination by a dermatologist, with dermoscopy often used for confirmation. In rare cases where the appearance is atypical, a skin biopsy may be performed to rule out skin cancer. Treatment is generally not medically necessary as seborrheic keratoses are harmless. However, they can be removed for cosmetic reasons or if they become irritated. Common removal methods include cryotherapy (freezing), electrocautery, curettage, or shave excision. Newer treatments like hydrogen peroxide topical solutions are also available for select cases."},
    { name: "Systemic Diseases", category: "Skin Conditions", severity: "Severe", info: "Systemic diseases often manifest with skin symptoms, serving as important diagnostic clues. Common examples include lupus erythematosus, scleroderma, diabetes, and various autoimmune disorders. Skin manifestations can range from rashes and lesions to changes in pigmentation and texture. Diagnosis typically involves a combination of clinical examination, patient history, and specific tests such as blood work, skin biopsies, and immunofluorescence studies. Treatment approaches vary depending on the underlying condition but often include a combination of topical and systemic therapies. Topical treatments may involve corticosteroids, immunomodulators, or specific medications for particular conditions. Systemic treatments can include oral corticosteroids, immunosuppressants, biologics, and disease-modifying drugs. Additionally, phototherapy and lifestyle modifications are often incorporated into treatment plans. Management of these conditions usually requires a multidisciplinary approach, with dermatologists working alongside other specialists to address both cutaneous and internal manifestations of the disease." },
    { name: "Tinea Ringworm Candidiasis", category: "Skin Conditions", severity: "Mild", info: "Tinea, ringworm, and candidiasis are common fungal infections affecting the skin and mucous membranes. Tinea and ringworm are caused by dermatophytes, while candidiasis is caused by Candida species. These infections present with various symptoms, including red, scaly patches (tinea), circular lesions (ringworm), and white patches or rashes (candidiasis). Diagnosis typically involves clinical examination, skin scrapings for microscopic analysis, and sometimes fungal cultures. Wood's lamp examination may be used for certain types of tinea. Treatment options depend on the location and severity of the infection. Topical antifungal medications like clotrimazole, miconazole, or terbinafine are often effective for mild to moderate cases. Oral antifungals such as fluconazole, itraconazole, or terbinafine may be prescribed for more severe or widespread infections. For candidiasis, nystatin is commonly used. Treatment duration varies but usually lasts several weeks. Preventive measures include maintaining good hygiene, keeping skin dry, and avoiding sharing personal items." },
    { name: "Urticaria Hives", category: "Skin Conditions", severity: "Moderate", info: "Urticaria, commonly known as hives, is a skin condition characterized by raised, itchy welts that can vary in size and appear anywhere on the body. It occurs when the body releases histamine in response to triggers such as allergens, medications, infections, or physical stimuli. Diagnosis is typically based on clinical presentation and patient history, with additional tests like blood work or skin biopsies performed to rule out underlying conditions in chronic cases. Treatment primarily focuses on symptom management and trigger avoidance. First-line therapy usually involves second-generation antihistamines, which can be increased to up to four times the standard dose if needed. For severe cases, short courses of oral corticosteroids may be prescribed. Other treatment options include H2 antihistamines, leukotriene receptor antagonists, and in chronic cases, omalizumab or cyclosporine. Lifestyle modifications, such as avoiding tight clothing and known triggers, can also help manage symptoms. Most acute cases resolve within 24 hours, but chronic urticaria may persist for weeks or months." },
    { name: "Vascular Tumors", category: "Skin Conditions", severity: "Moderate", info: "Vascular tumors are abnormal growths of blood vessels, ranging from benign to malignant. The most common type is infantile hemangioma, typically appearing in infancy. Diagnosis involves clinical examination, patient history, and imaging studies such as MRI, CT scans, or ultrasound. In some cases, a biopsy may be necessary for definitive diagnosis. Treatment options vary depending on the tumor type, location, and severity. For benign tumors like infantile hemangiomas, observation is often sufficient as many resolve spontaneously. When intervention is necessary, options include beta-blocker therapy (e.g., propranolol), corticosteroids, laser therapy, or surgical excision. For more aggressive or malignant tumors like angiosarcomas, treatment typically involves a combination of surgery, radiation therapy, and chemotherapy. Newer targeted therapies and immunotherapies are also being explored for certain types of vascular tumors. Management often requires a multidisciplinary approach, with long-term follow-up to monitor for recurrence or complications." },
    { name: "Vasculitis", category: "Skin Conditions", severity: "Moderate", info: "Vasculitis is a group of disorders characterized by inflammation of blood vessel walls, affecting vessels of various sizes throughout the body. It can be systemic or localized, causing a wide range of symptoms depending on the affected organs. Diagnosis typically involves a combination of clinical examination, patient history, blood tests, urine analysis, imaging studies (such as CT, MRI, or angiography), and sometimes tissue biopsies. Treatment is generally divided into two phases: remission induction and maintenance. Common treatments include corticosteroids like prednisone to reduce inflammation, often combined with immunosuppressive drugs such as cyclophosphamide, methotrexate, or azathioprine. Newer biologic therapies like rituximab may be used in certain cases. The choice of treatment depends on the specific type of vasculitis, its severity, and organ involvement. Long-term monitoring is essential, as many forms of vasculitis require ongoing management to prevent relapses and minimize organ damage. Lifestyle modifications and regular exercise can help manage symptoms and treatment side effects." },
    { name: "Warts Molluscum", category: "Skin Conditions", severity: "Mild", info: "Warts and molluscum contagiosum are common viral skin infections. Warts are caused by human papillomavirus (HPV) and appear as rough, raised growths, while molluscum contagiosum, caused by a poxvirus, presents as small, smooth, dome-shaped bumps. Both conditions are typically diagnosed through clinical examination, with biopsy rarely needed. Treatment options for warts include topical salicylic acid, cryotherapy, and immunotherapy. For molluscum contagiosum, treatment may involve watchful waiting, as lesions often resolve spontaneously within months to years. However, physical removal methods like curettage or cryotherapy can be employed. Topical medications such as imiquimod or podophyllotoxin may also be used. In both conditions, treatment choice depends on the location, extent of infection, and patient preference. Prevention involves avoiding direct contact with infected individuals or contaminated objects. For immunocompromised patients, these infections can be more extensive and challenging to treat, often requiring more aggressive management." },

    // Eye Conditions
    { name: "Cataract", category: "Eye Conditions", severity: "Moderate", info: "Cataracts are a common age-related eye condition characterized by the clouding of the eye's natural lens, leading to blurred vision, glare sensitivity, and decreased color perception. They typically develop slowly over time and can affect one or both eyes. Diagnosis involves a comprehensive eye exam, including visual acuity tests, slit-lamp examination, and dilated eye exam. Early-stage cataracts may be managed with updated eyeglass prescriptions and improved lighting. However, as the condition progresses, surgical intervention becomes necessary. The standard treatment is cataract surgery, a safe and effective outpatient procedure where the cloudy lens is removed and replaced with an artificial intraocular lens (IOL). Various types of IOLs are available, including monofocal, multifocal, and toric lenses, allowing customization based on the patient's visual needs. Post-operative care typically involves eye drops and follow-up appointments. With modern surgical techniques, most patients experience significant improvement in vision and quality of life following cataract surgery." },

    // Nail Conditions
    { name: "Darier's Disease", category: "Nail Conditions", severity: "Moderate", info: "Darier's disease, also known as keratosis follicularis, is a rare autosomal dominant genetic disorder characterized by greasy, scaly papules in seborrheic areas and skin folds. It typically manifests in the first or second decade of life and is caused by mutations in the ATP2A2 gene, affecting calcium transport within cells. Diagnosis is based on clinical presentation, family history, and skin biopsy showing characteristic histopathological features of focal acantholytic dyskeratosis. Common symptoms include red-brown papules, nail abnormalities, and mucous membrane involvement. Treatment options are primarily aimed at managing symptoms and preventing flare-ups. Topical and oral retinoids, such as isotretinoin and acitretin, are considered first-line therapies. Other treatments include topical steroids, vitamin D analogs, and photodynamic therapy. For severe cases, systemic immunomodulators like cyclosporine may be considered. Lifestyle modifications to avoid triggers like heat, friction, and UV exposure are essential. While there is no cure, most patients can achieve symptom control with appropriate management." },
    { name: "Muehrck-e's lines", category: "Nail Conditions", severity: "Mild", info: "Muehrcke's lines are a nail condition characterized by paired, white, transverse lines that run parallel to the lunula (the white crescent-shaped area at the base of the nail). Unlike other nail abnormalities, these lines do not move with nail growth and disappear when pressure is applied to the nail plate. Muehrcke's lines are typically associated with hypoalbuminemia, a condition where blood albumin levels are abnormally low, often due to conditions such as liver disease, nephrotic syndrome, or malnutrition. Diagnosis is primarily based on visual examination of the nails and confirmation of low serum albumin levels through blood tests. Treatment focuses on addressing the underlying cause of hypoalbuminemia rather than treating the nail changes directly. As albumin levels normalize, Muehrcke's lines usually fade or disappear. In cases of severe protein deficiency, albumin infusions may be necessary. Regular monitoring of albumin levels and overall health is important for managing this condition." },
    { name: "Beau's lines", category: "Nail Conditions", severity: "Mild", info: "Beau's lines are horizontal depressions or grooves that run across the nail plate, resulting from a temporary interruption in nail growth. They can affect fingernails and toenails, often appearing on multiple nails simultaneously. Common causes include systemic illnesses, medications (particularly chemotherapy), infections, nutritional deficiencies, and physical trauma to the nail matrix. Diagnosis is primarily based on visual examination of the nails and a thorough medical history to identify potential underlying causes. Treatment focuses on addressing the root cause rather than the lines themselves. As the nail grows, Beau's lines will typically grow out on their own. In cases where the appearance is cosmetically bothersome, gentle buffing or gel nail applications can help conceal the grooves. It's important to consult a healthcare professional if Beau's lines appear, as they can be indicative of underlying health issues. Once the causative factor is resolved, normal nail growth usually resum" },
    { name: "Bluish nail", category: "Nail Conditions", severity: "Mild", info: "Bluish nails, medically known as cyanosis, occur when there's insufficient oxygen in the blood or poor circulation to the extremities. This condition can affect fingernails and toenails, appearing as a blue or purplish discoloration. Common causes include cold temperatures, lung diseases, heart conditions, blood disorders, and certain medications. Diagnosis typically involves a physical examination, medical history review, and tests such as pulse oximetry or arterial blood gas analysis to measure blood oxygen levels. In some cases, imaging studies or blood tests may be necessary to identify underlying conditions. Treatment primarily focuses on addressing the root cause. This may involve warming the affected area for cold-induced cyanosis, medications for heart or lung diseases, or lifestyle changes to improve circulation. In severe cases or when associated with other symptoms like chest pain or shortness of breath, immediate medical attention is required. Regular monitoring and follow-up care are essential for managing chronic conditions that cause bluish nails." },
    { name: "Clubbing", category: "Nail Conditions", severity: "Moderate", info: "Nail clubbing is a physical sign characterized by enlargement of the fingertips and a change in the angle between the nail plate and the nail fold, resulting in a rounded, spoon-like appearance. It's often associated with underlying conditions, particularly lung and heart diseases. Diagnosis is primarily based on visual examination and measurements such as the Lovibond angle or Schamroth sign. Additional tests like chest X-rays, CT scans, or blood work may be performed to identify the underlying cause. Treatment focuses on addressing the primary condition rather than the clubbing itself. Common causes include lung cancer, chronic lung infections, cardiovascular diseases, and liver cirrhosis. In some cases, clubbing may be hereditary or idiopathic. While clubbing itself is not harmful, it serves as an important clinical indicator of potentially serious health issues. With successful treatment of the underlying condition, clubbing may reverse in some cases, although long-standing changes may be permanent." },
    { name: "Half and Half Nails (Lindsay's nails)", category: "Nail Conditions", severity: "Mild", info: "Half and half nails, also known as Lindsay's nails, are a nail abnormality characterized by a white proximal portion and a red, pink, or brown distal portion, with a sharp demarcation line between the two halves. This condition is often associated with chronic kidney disease, affecting up to 40% of patients on hemodialysis, but can also occur in other systemic diseases or in healthy individuals. Diagnosis is primarily based on visual examination of the nails, with the distal discoloration typically occupying 20-60% of the nail bed and not fading under pressure. While the exact cause is unclear, it may be related to increased β-melanocyte-stimulating hormone levels. No specific treatment is required for the nail changes themselves, as they are generally benign. Management focuses on addressing the underlying condition, such as kidney disease. In some cases, the nail appearance may improve after kidney transplantation. Regular monitoring and follow-up care are essential for managing associated health issues." },
    { name: "Koilonychia", category: "Nail Conditions", severity: "Mild", info: "Koilonychia, also known as spoon nails, is a nail abnormality characterized by thin, soft nails with a concave shape resembling a spoon. The most common cause is iron deficiency anemia, but it can also result from trauma, autoimmune conditions, or genetic factors. Diagnosis involves visual examination of the nails, medical history review, and blood tests to check for underlying conditions, particularly iron levels. Treatment primarily focuses on addressing the root cause. For iron deficiency, dietary changes and iron supplements are typically prescribed. In cases related to other conditions, such as lupus or psoriasis, managing the underlying disease is crucial. Environmental factors, like frequent exposure to petroleum products, may also contribute to koilonychia. While spoon nails in infants often resolve on their own, adults should seek medical attention for proper diagnosis and treatment. Recovery time varies, with fingernails taking about 6 months and toenails up to 18 months to regrow fully after treatment begins." },
    { name: "Leukonychia", category: "Nail Conditions", severity: "Mild", info: "Leukonychia refers to the whitening of the nail plate, which can be partial or complete. It is categorized into three types: true leukonychia (affecting the nail plate), apparent leukonychia (involving the nail bed), and pseudoleukonychia (surface changes). Common causes include trauma, nutritional deficiencies, systemic diseases, and certain medications. Diagnosis is primarily based on visual examination and patient history. In some cases, additional tests may be necessary to identify underlying conditions. True leukonychia often appears as white spots or lines and grows out with the nail, while apparent leukonychia may indicate liver or kidney disease. Treatment depends on the underlying cause. For trauma-induced leukonychia, no specific treatment is needed as it will grow out naturally. Nutritional deficiencies are addressed through dietary changes or supplements. In cases related to systemic diseases, managing the primary condition is crucial. Most cases of leukonychia are benign and resolve on their own, but persistent or widespread cases warrant medical evaluation to rule out more serious conditions." },
    { name: "Onycholycis", category: "Nail Conditions", severity: "Mild", info: "Onycholysis is a condition characterized by the separation of the nail plate from the underlying nail bed, typically starting at the distal or lateral edge and progressing proximally. It can affect fingernails or toenails and is often painless. Common causes include trauma, fungal infections, psoriasis, contact dermatitis, and certain medications. Diagnosis is primarily based on visual examination and patient history. In some cases, additional tests like fungal cultures or nail biopsies may be necessary to determine the underlying cause. Treatment focuses on addressing the root cause and promoting nail reattachment. This may involve avoiding trauma, treating fungal infections with antifungal medications, managing psoriasis, or discontinuing offending medications. Keeping nails short and dry, avoiding harsh chemicals, and using protective gloves can aid recovery. In severe cases, partial or complete nail removal may be necessary. Recovery time varies, with fingernails taking about 6 months and toenails up to 18 months to regrow fully. Proper nail care and addressing underlying conditions are crucial for preventing recurrence." },
    { name: "Pale Nail", category: "Nail Conditions", severity: "Mild", info: "Pale nails, characterized by a lack of pinkness in the nail bed, can be indicative of various underlying health conditions. Common causes include anemia, liver disease, congestive heart failure, malnutrition, and certain medications. Diagnosis typically involves a thorough physical examination, medical history review, and often blood tests to check for anemia or liver function abnormalities. Additional tests may be necessary depending on suspected underlying conditions. Treatment primarily focuses on addressing the root cause. For anemia, iron supplements or dietary changes may be prescribed. Liver or heart conditions require specific medical interventions. In cases of malnutrition, nutritional support and dietary improvements are essential. If medication-induced, adjusting or changing the medication may be necessary. While pale nails themselves are not harmful, they serve as an important clinical indicator of potentially serious health issues. Regular monitoring and follow-up care are crucial for managing associated conditions. In many cases, nail color returns to normal once the underlying issue is resolved." },
    { name: "Red Lunula", category: "Nail Conditions", severity: "Mild", info: "Red lunula is a rare nail condition characterized by a reddish discoloration of the lunula, the crescent-shaped white area at the base of the nail. It is often associated with various systemic conditions, including lupus erythematosus, rheumatoid arthritis, alopecia areata, cardiac failure, hepatic cirrhosis, and carbon monoxide poisoning. Diagnosis is primarily based on visual examination of the nails and a thorough medical history. Additional tests may be necessary to identify underlying causes. The exact mechanism is not fully understood but may involve increased arteriolar blood flow or changes in the optical properties of the nail plate. Treatment focuses on addressing the underlying condition rather than the nail appearance itself. For instance, managing lupus or heart failure may lead to improvement in the red lunula. In some cases, the condition may resolve on its own once the underlying issue is treated. Regular monitoring and follow-up care are essential, as red lunulae can be an important clinical indicator of potentially serious health issues." },
    { name: "Splinter Hemmorrages", category: "Nail Conditions", severity: "Mild", info: "Splinter hemorrhages are thin, red or reddish-brown lines that appear under the nails, resembling wood splinters. They occur when small blood vessels in the nail bed burst, often due to trauma, but can also indicate underlying health conditions such as endocarditis, psoriasis, or vasculitis. Diagnosis typically involves a physical examination and medical history review. In some cases, a dermoscopy may be performed for a clearer view. While splinter hemorrhages caused by injury usually resolve on their own without treatment, those resulting from underlying conditions require addressing the root cause. Treatment options vary depending on the underlying issue and may include antibiotics for infections, corticosteroids for inflammatory conditions, or adjusting medications if they are the cause. Most cases are benign, but multiple affected nails or unexplained occurrences warrant medical evaluation to rule out more serious conditions. The prognosis is generally good, with hemorrhages often clearing up as the nail grows out or the underlying condition is treated." },
    { name: "Terry's nail", category: "Nail Conditions", severity: "Mild", info: "Terry's nails is a condition characterized by a white, \"ground glass\" appearance of the nail plate with a dark band at the nail tip, typically without a visible lunula. It is often associated with severe systemic diseases, particularly liver cirrhosis, but can also occur with diabetes, congestive heart failure, kidney failure, or as part of normal aging. The condition is thought to result from decreased vascularity and increased connective tissue in the nail bed. Diagnosis is primarily based on visual examination of the nails and evaluation of the patient's medical history. Additional tests may be performed to identify underlying conditions. There is no specific treatment for Terry's nails itself; instead, management focuses on addressing the underlying cause. In many cases, the nail appearance may improve or resolve once the primary condition is treated. Regular monitoring and follow-up care are essential, as Terry's nails can be an important clinical indicator of potentially serious health issues." },
    { name: "White nail", category: "Nail Conditions", severity: "Mild", info: "White nails, also known as leukonychia, are characterized by a partial or complete whitening of the nail plate. This condition can be caused by various factors, including trauma, nutritional deficiencies, systemic diseases, or medications. Diagnosis is primarily based on visual examination of the nails and a thorough medical history. Additional tests may be necessary to identify underlying conditions such as anemia, liver disease, or kidney problems. Treatment depends on the underlying cause. For trauma-induced leukonychia, no specific treatment is needed as it will grow out naturally. Nutritional deficiencies are addressed through dietary changes or supplements. In cases related to systemic diseases, managing the primary condition is crucial. Fungal infections may require antifungal medications. Prevention involves avoiding nail trauma, maintaining good nutrition, and addressing underlying health issues. While most cases of white nails are benign, persistent or widespread occurrences warrant medical evaluation to rule out more serious conditions. Regular nail care and moisturizing can help maintain overall nail health." },
    { name: "Yellow nails", category: "Nail Conditions", severity: "Mild", info: "Yellow nail syndrome is a rare condition characterized by thickened, slow-growing nails with a yellow-green discoloration, often accompanied by respiratory issues and lymphedema. The exact cause is unknown, but it's associated with various systemic diseases, including respiratory disorders, lymphatic system abnormalities, and certain medications. Diagnosis is primarily based on clinical presentation, with at least two of the three main features (yellow nails, lymphedema, and respiratory symptoms) typically required. Additional tests may include chest X-rays, pulmonary function tests, and lymph node imaging. Treatment focuses on managing underlying conditions and symptoms. Oral vitamin E supplements, topical vitamin E oil, and antifungal medications may help improve nail appearance. Respiratory symptoms are managed with antibiotics, chest physiotherapy, or inhaled corticosteroids. Lymphedema treatment involves compression therapy, manual lymphatic drainage, and skin care. In severe cases, surgical options like nail avulsion or matrixectomy may be considered. While there's no cure, many patients experience improvement with appropriate management of associated conditions." },

    // Melanoma
    { name: "Melanoma Skin Cancer Nevi and Moles", category: "Melanoma", severity: "Severe", info: "Melanoma is an aggressive form of skin cancer that develops from melanocytes, often originating in existing moles or nevi. It's characterized by irregular, asymmetrical growths with varied colors and borders. Diagnosis typically involves visual examination using the ABCDE criteria (Asymmetry, Border irregularity, Color variation, Diameter >6mm, and Evolution), followed by a biopsy for confirmation. Treatment depends on the stage of the cancer. Early-stage melanomas are usually treated with surgical excision, while more advanced cases may require lymph node biopsy, immunotherapy, targeted therapy, or radiation. For stage IV melanoma, options include immunotherapy drugs like checkpoint inhibitors, targeted therapy for tumors with specific gene mutations, and sometimes chemotherapy. Regular skin checks and prompt attention to changing moles are crucial for early detection. While most moles and nevi are benign, atypical moles (dysplastic nevi) can indicate an increased risk of melanoma and should be monitored closely." },
    { name: "Benign", category: "Melanoma", severity: "Mild", info: "Benign moles, or nevi, are common skin growths composed of melanocytes. Unlike melanoma, benign moles are typically symmetrical, have regular borders, uniform color, and a diameter less than 6mm. They can be flat or raised, and range in color from pink to dark brown. Diagnosis is usually made through visual examination by a dermatologist using the ABCDE criteria (Asymmetry, Border, Color, Diameter, Evolution). In some cases, dermoscopy or biopsy may be used for confirmation. While benign moles generally don't require treatment, they may be removed for cosmetic reasons or if they cause irritation. Common removal methods include shave excision, punch excision, or excisional surgery. It's important to monitor benign moles for changes, as some can develop into melanoma over time. Regular self-examinations and annual skin checks by a dermatologist are recommended, especially for individuals with numerous moles or a family history of melanoma." },
    { name: "Malignant", category: "Melanoma", severity: "Severe", info: "Malignant melanoma is an aggressive form of skin cancer that develops from melanocytes, often originating in existing moles or new skin growths. It's characterized by asymmetrical, irregularly bordered lesions with varied colors and diameters typically larger than 6mm. Diagnosis involves visual examination using the ABCDE criteria (Asymmetry, Border irregularity, Color variation, Diameter, and Evolution), followed by a biopsy for confirmation. Treatment depends on the stage of the cancer. Early-stage melanomas are usually treated with surgical excision, while more advanced cases may require lymph node biopsy, immunotherapy, targeted therapy, or radiation. For metastatic melanoma, options include immunotherapy drugs like checkpoint inhibitors, targeted therapy for tumors with specific gene mutations, and sometimes chemotherapy. Regular skin checks and prompt attention to changing moles are crucial for early detection. Prognosis varies widely based on the stage at diagnosis, with early detection significantly improving outcomes." },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMobileMenuOpen(false);
  };

  const filteredConditions = allConditions.filter(condition => 
    (selectedCategory === 'All' || condition.category === selectedCategory) &&
    (condition.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     condition.category.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const toggleConditionInfo = (conditionName: string) => {
    setExpandedCondition(expandedCondition === conditionName ? null : conditionName);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-50 to-teal-50 text-gray-800">
      <header className="p-4 flex justify-between items-center bg-white bg-opacity-80 backdrop-blur-md">
        <h1 className="text-xl md:text-2xl font-bold text-indigo-600">HealthLens</h1>
        <nav className="hidden md:flex items-center space-x-4">
          <Button variant="ghost" onClick={() => handleNavigation('/')}>Home</Button>
          <Button variant="ghost" onClick={() => handleNavigation('/about')}>About</Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="icon" onClick={toggleDropdown}>
                <User className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            {isDropdownOpen && (
              <DropdownMenuContent align="end">
                <DropdownMenuItem onSelect={() => handleNavigation('/login')}>Login</DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleNavigation('/signup')}>Sign Up</DropdownMenuItem>
              </DropdownMenuContent>
            )}
          </DropdownMenu>
        </nav>
        <Button variant="outline" size="icon" className="md:hidden" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <X className="h-4 w-4" /> : <Menu className="h-4 w-4" />}
        </Button>
      </header>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="md:hidden bg-white shadow-lg p-4 absolute top-16 left-0 right-0 z-50"
          >
            <Button variant="ghost" className="w-full justify-start mb-2" onClick={() => handleNavigation('/')}>Home</Button>
            <Button variant="ghost" className="w-full justify-start mb-2" onClick={() => handleNavigation('/about')}>About</Button>
            <Button variant="ghost" className="w-full justify-start mb-2" onClick={() => handleNavigation('/login')}>Login</Button>
            <Button variant="ghost" className="w-full justify-start mb-2" onClick={() => handleNavigation('/signup')}>Sign Up</Button>
          </motion.div>
        )}
      </AnimatePresence>

      <main className="container mx-auto px-4 py-8 md:py-12">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-6xl mx-auto"
        >
          <div className="bg-white rounded-xl shadow-lg p-4 md:p-6 mb-8">
            <h1 className="text-2xl md:text-3xl font-bold text-indigo-800 mb-4">HealthLens Health Encyclopedia</h1>
            <p className="text-indigo-600 mb-6">Your Comprehensive Guide to Skin, Eye, and Nail Conditions</p>
            
            <section className="mb-8">
              <h2 className="text-xl md:text-2xl font-bold text-indigo-700 mb-4">Major Categories</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                {mainCategories.map((category) => (
                  <Button
                    key={category.name}
                    variant={selectedCategory === category.name ? "default" : "outline"}
                    className="h-16 md:h-24 text-sm md:text-lg flex flex-col items-center justify-center"
                    onClick={() => setSelectedCategory(category.name)}
                  >
                    <span className="text-2xl md:text-3xl mb-1 md:mb-2">{category.icon}</span>
                    {category.name}
                  </Button>
                ))}
              </div>
            </section>

            <div className="relative mb-8">
              <input
                type="text"
                placeholder="Search conditions..."
                className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>

            <section>
              <h2 className="text-xl md:text-2xl font-bold text-indigo-700 mb-4">Condition List</h2>
              <div className="bg-white shadow overflow-hidden sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                  {filteredConditions.map((condition) => (
                    <li key={condition.name}>
                      <div className="block hover:bg-indigo-50">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <button 
                              onClick={() => toggleConditionInfo(condition.name)}
                              className="text-sm font-medium text-indigo-600 truncate flex items-center"
                            >
                              {condition.name}
                              {expandedCondition === condition.name ? (
                                <ChevronUp className="ml-2 h-4 w-4" />
                              ) : (
                                <ChevronDown className="ml-2 h-4 w-4" />
                              )}
                            </button>
                            <div className="ml-2 flex-shrink-0 flex">
                              <p className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                condition.severity === 'Mild' ? 'bg-green-100 text-green-800' :
                                condition.severity === 'Moderate' ? 'bg-yellow-100 text-yellow-800' :
                                condition.severity === 'Mild to Moderate' ? 'bg-yellow-100 text-yellow-800' :
                                'bg-red-100 text-red-800'
                              }`}>
                                {condition.severity}
                              </p>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <p className="flex items-center text-sm text-indigo-500">
                                {condition.category}
                              </p>
                            </div>
                          </div>
                          
                          {expandedCondition === condition.name && (
                            <div className="mt-2 text-sm text-gray-600">
                              <p
                                dangerouslySetInnerHTML={{ __html: conditionInfo[condition.name] || condition.info }}
                                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </div>
        </motion.div>
      </main>
    </div>
  );
}